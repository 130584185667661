<!--
 * @Author: Will
 * @Date: 2022-01-12 18:03:36
 * @LastEditors: Will
 * @LastEditTime: 2022-02-15 10:47:32
-->
<template>
  <div class="main">
    <div id="loading">
      <div id="loading-center">
        <div id="loading-center-absolute">
          <div class="object" id="object_one"></div>
          <div class="object" id="object_two"></div>
          <div class="object" id="object_three"></div>
          <div class="object" id="object_four"></div>
          <div class="object" id="object_five"></div>
          <div class="object" id="object_six"></div>
          <div class="object" id="object_seven"></div>
          <div class="object" id="object_eight"></div>
          <div class="object" id="object_big"></div>
        </div>
      </div>
    </div>
    <div class="txt">
      <div class="p">{{ $t("正在验证您的身份，请稍等") }}</div>
    </div>
  </div>
</template>
<script>
import { reactive, onMounted, computed, getCurrentInstance} from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { ssoPCLogin } from "@/api/user";
import { successCallback } from "@/utils/login.js";
export default {
  setup() {
    const route = useRoute();
    const store = useStore();
    const { proxy } = getCurrentInstance();
    const config = computed(() => store.getters.platformConfig);
    const state = reactive({
      account: route.query.account || "",
      sign: route.query.sign || "",
      accesstoken: route.query.accesstoken || "",
      timestamp: route.query.timestamp || "",
      redirect: decodeURIComponent(route.query.redirect || "/"),
    });

    onMounted(() => {
      fnLogin();
    });

    const fnLogin = () => {
      ssoPCLogin({
        account: state.account,
        sign: state.sign,
        accesstoken: state.accesstoken,
        timestamp: state.timestamp,
      }).then((res) => {
        if (res.ret == 0) {
          if (config.value.customerCode === 'dfzx') {
            state.redirect = '/mine'
          }
          successCallback(res, state);
          store.dispatch("setPlatform", "web");
        } else {
          proxy.$message.error(res.msg);
        }
      });
    };
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/style/login.less";
</style>
